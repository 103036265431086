<template>
  <div>
    <ServerError v-if="ServerError" />
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="spinner"
    />
    <v-layout wrap justify-center>
      <v-flex xs12 lg11 pt-lg-6>
        <v-card class="mx-auto elevation-2 pa-6" align="center" outlined>
          <!----------------------filters---------------------------->
          <v-layout wrap>
            <v-flex xs12 sm9 md10 pb-2 class="nsbold text-left"
              ><span style="font-size: 20px">Outpass Report</span></v-flex
            >
            <v-flex xs12 sm2 md2 pb-2>
              <download-excel :data="myData" :fields="json_fields">
                <v-btn
                  class="hidden-xs-only"
                  width="155px"
                  dark
                  style="text-transform: none"
                  color="#766BC0"
                  @click="getExcel()"
                  >Download Excel</v-btn
                >
                <v-btn
                  class="hidden-sm-and-up"
                  width="155px"
                  dark
                  block
                  style="text-transform: none"
                  color="#766BC0"
                  @click="getExcel()"
                  >Download Excel</v-btn
                >
              </download-excel>
            </v-flex>
            <v-flex xs12 sm6 md6 lg3 pb-sm-2 pb-2 pb-lg-0>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    readonly
                    dense
                    hide-details
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 md6 lg3 pl-sm-4 pb-2 pb-lg-0>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    hide-details
                    outlined
                    dense
                    clearable
                    class="rounded-0"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
            </v-flex>
            <v-flex xs12 sm6 md6 lg3 pl-lg-4 pb-2 pb-lg-0>
              <v-text-field
                v-model="keyword"
                dense
                hide-details
                label="Search : Ad.no/Name"
                outlined
                clearable
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6 lg3 pl-sm-4>
              <v-select
                :items="issueStatus"
                v-model="cvalue"
                label="Status"
                outlined
                hide-details
                dense
              ></v-select>
            </v-flex>
            <!--------------------------list------------------------>
          </v-layout>
          <v-layout wrap justify-center>
            <v-flex xs12 v-if="outpass">
              <v-layout wrap class="nsbold" style="font-size: 16px">
                <v-flex xs12 pa-md-2 v-if="outpass.length > 0">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">No.</th>
                          <th class="text-left">Admission No</th>
                          <th class="text-left">Name</th>
                          <th class="text-left">Outpass.No.</th>
                          <th class="text-left">Status</th>
                          <th class="text-left" style="width: 10%">
                            Outpass Date
                          </th>
                          <th class="text-left">From</th>
                          <th class="text-left">To</th>
                          <th class="text-left">Issued Date</th>
                          <th class="text-left">Student In</th>
                          <th class="text-left">View</th>
                          <th class="text-left">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in outpass" :key="i">
                          <td>
                            <span v-if="page == 1">
                              {{ i + 1 }}
                            </span>
                            <span v-else>
                              {{ i + 1 + 20 * (page - 1) }}
                            </span>
                          </td>
                          <td>
                            {{ item.admissionNo }}
                          </td>
                          <td>
                            <router-link
                              style="text-decoration: none; color: black"
                              :to="'/studentOutpass?id=' + item.studentid._id"
                            >
                              {{ item.studentid.name }}
                            </router-link>
                          </td>
                          <td>
                            <span v-if="item.outPassNo">
                              {{ item.outPassNo }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.issueStatus">
                              {{ item.issueStatus }}
                            </span>
                            <span v-else>NA</span>
                          </td>
                          <td>
                            <span v-if="item.fromDate">
                              {{ formatDate2(item.fromDate) }}
                            </span>
                            <span v-else>NA</span>
                          </td>

                          <td>
                            <span v-if="item.fromDate">
                              {{ formatDate3(item.fromDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.toDate">
                              {{ formatDate3(item.toDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>

                          <td>
                            <span v-if="item.outDate">
                              {{ formatDate(item.outDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <span v-if="item.inDate">
                              {{ formatDate(item.inDate) }}
                            </span>
                            <span v-else>-</span>
                          </td>
                          <td>
                            <v-dialog
                              v-model="item.dialog"
                              persistent
                              max-width="590"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  v-bind="attrs"
                                  v-on="on"
                                  style="cursor: pointer; color: #766bc0"
                                  >mdi-eye</v-icon
                                >
                              </template>
                              <v-card>
                                <v-layout wrap>
                                  <v-flex xs12 px-4 pt-4>
                                    <v-layout wrap>
                                      <v-flex xs12 sm6 text-sm-left>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                          Admission No: {{ item.admissionNo }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 sm6 text-sm-right>
                                        <span
                                          class="nsbold"
                                          style="font-size: 16px"
                                        >
                                          Outpass No: {{ item.outPassNo }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                    <v-divider></v-divider>
                                    <v-layout wrap pa-md-4>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Name:</b> {{ item.studentid.name }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          v-if="item.outPassIssued"
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Isssued On:</b>
                                          {{ formatDate(item.create_date) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>From:</b>
                                          {{ formatDate(item.fromDate) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          v-if="item.outPassIssued"
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Isssued By:</b>
                                          {{ item.outPassIssued.name }}
                                        </span>
                                      </v-flex>

                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>To:</b>
                                          {{ formatDate(item.toDate) }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b> Status:</b> {{ item.issueStatus }}
                                        </span>
                                      </v-flex>

                                      <v-flex xs12 md6 text-md-left>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Purpose:</b> {{ item.purpose }}
                                        </span>
                                      </v-flex>
                                      <v-flex xs12 md6 text-md-right>
                                        <span
                                          class="nsregular"
                                          style="font-size: 14px"
                                        >
                                          <b>Destination:</b>
                                          {{ item.destination }}
                                        </span>
                                      </v-flex>
                                    </v-layout>
                                  </v-flex>
                                </v-layout>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <!-- <v-btn
                                    color="green darken-1"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Disagree
                                  </v-btn> -->
                                  <v-btn
                                    color="#766BC0"
                                    text
                                    @click="item.dialog = false"
                                  >
                                    Close
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </td>
                          <td>
                            <v-btn @click="approveOutPass(item.admissionNo)" v-if="item.issueStatus=='Out'" dark block small
                                color="#766bc0"
                                  >Admit In</v-btn
                                >
                                <v-btn v-else  block small
                                color="#766bc0" disabled
                                  >Admitted</v-btn
                                >
                                <!-- <v-btn small solo v-if="item.issueStatus=='Out'">
                                <v-icon style="cursor:pointer; color:green"  v-if="item.issueStatus=='Out'">mdi-check</v-icon>
                              </v-btn> -->
                          </td>
                          <!-- <td>
                                          <span
                                            v-if="item.clothid.clothType.name"
                                          >
                                            {{ item.clothid.clothType.name }}
                                          </span>
                                          <span v-else>NA</span>
                                        </td>
                                        <td>
                                          {{ formatDate(item.update_date) }}
                                        </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
                <v-flex v-else xs12 align-self-center text-center pa-2>
                  <span class="nsregular">Currently no item found!</span>
                </v-flex>
              </v-layout>
              <v-pagination
                small
                color="#766BC0"
                total-visible="7"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      page: 1,
      Pagelength: 0,
      menu: false,
      menu2: false,
      toDate: null,
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      // toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      keyword: null,
      msg: null,
      // download excel
      myData: [],
      excel: {
        outno: "",
        adno: "",
        name: "",
        from: "",
        to: "",
        destination: "",
        purpose: "",
        issuedon: "",
        out: "",
        in: "",
      },
      json_fields: {
        Outpass_No: "outno",
        Ad_No: "adno",
        Name: "name",
        From_time: "from",
        To_time: "to",
        Destination: "destination",
        Purpose: "purpose",
        Outpass_Issued_on: "issuedon",
        Student_actual_out_date: "out",
        Student_actual_in_date: "in",
      },
      //
      issueStatus: ["All", "Out", "In"],
      cvalue: "All",
      showsnackbar: false,
      ServerError: false,
      appLoading: false,
      outpass: [],
      from: null,
      to: null,
      date: null,
    };
  },
  mounted() {
    if (this.fromDate) {
      var dt = new Date();

      // var day=dt.getDate()
      dt.setDate(dt.getDate() - 30 + 1);
      this.fromDate = dt.toISOString().substr(0, 10);
    }
    if (this.from) {
      var dlt = new Date();

      // var day=dlt.getDate()
      dlt.setDate(dlt.getDate() - 30 + 1);
      this.fromDate = dlt.toISOString().substr(0, 10);
    }

    if (this.$route.query.stat == "Active") {
      this.cvalue = "In";
    }
    if (this.$route.query.curdate) {
      this.fromDate = this.$route.query.curdate;
      this.toDate = this.$route.query.curdate;
    }
    this.getData();
    this.getExcel();
  },
  watch: {
    page() {
      // this.getData();
      this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
      this.getExcel();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
      this.getExcel();
    },
    keyword() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      this.getExcel();
    },
    cvalue() {
      if (this.page > 1) this.page = 1;
      // this.getData();
      this.getData();
      this.getExcel();
    },
  },
  methods: {
    approveOutPass(adno) {
      this.appLoading = true;
      axios({
        method: "post",
        url: "/outpass/student/in",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          QR: adno,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Student Admitted In Successfuly";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
          this.getData();
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getData() {
      this.appLoading = true;

      let a;
      if (this.cvalue == "All") {
        a = "";
      } else if (this.cvalue == "Issued") {
        a = "Active";
      } else {
        a = this.cvalue;
      }

      axios({
        method: "post",
        url: "outpass/report",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 20,
          issueStatus: a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.myData = [];
            this.outpass = response.data.data;
            for (let i = 0; i < this.outpass.length; i++) {
              if (this.outpass[i].issueStatus == "Active") {
                this.outpass[i].issueStatus = "Issued";
              }
            }
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    getExcel() {
      this.appLoading = true;

      let a;
      if (this.cvalue == "All") {
        a = "";
      } else if (this.cvalue == "Issued") {
        a = "Active";
      } else {
        a = this.cvalue;
      }

      axios({
        method: "post",
        url: "outpass/report",

        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          issueStatus: a,
          keyword: this.keyword,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            // this.outpass = response.data.data;
            this.myData = [];

            for (let i = 0; i < this.outpass.length; i++) {
              if (this.outpass[i].issueStatus == "Active") {
                this.outpass[i].issueStatus = "Issued";
              }
            }
            // this.Pagelength = Math.ceil(response.data.totalLength / 20);
            //excel
            for (let i = 0; i < response.data.data.length; i++) {
              if (response.data.data[i].outPassNo) {
                this.excel.outno = response.data.data[i].outPassNo;
              }
              if (response.data.data[i].admissionNo) {
                this.excel.adno = response.data.data[i].admissionNo;
              }
              if (response.data.data[i].studentid.name) {
                this.excel.name = response.data.data[i].studentid.name;
              }
              if (response.data.data[i].fromDate) {
                this.excel.from = this.formatDate(
                  response.data.data[i].fromDate
                );
              }
              if (response.data.data[i].toDate) {
                this.excel.to = this.formatDate(response.data.data[i].toDate);
              }
              if (response.data.data[i].destination) {
                this.excel.destination = response.data.data[i].destination;
              }
              if (response.data.data[i].purpose) {
                this.excel.purpose = response.data.data[i].purpose;
              }
              if (response.data.data[i].create_date) {
                this.excel.issuedon = this.formatDate(
                  response.data.data[i].create_date
                );
              }
              if (response.data.data[i].outDate) {
                this.excel.out = this.formatDate(response.data.data[i].outDate);
              }
              if (response.data.data[i].inDate) {
                this.excel.in = this.formatDate(response.data.data[i].inDate);
              }

              this.myData.push(JSON.parse(JSON.stringify(this.excel)));
              this.excel = {};
            }
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " , " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;

      return strTime;
    },
    formatDate2(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
    formatDate3(item) {
      var dt = new Date(item);
      // var day = dt.getDate();
      // var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        // day +
        // " " +
        // dt.slice(4, 7) +
        // " " +
        // year
        //  +
        // " , " +
        hours + ":" + minutes + " " + ampm;

      return strTime;
    },
  },
};
</script>
